<template>
  <div>
    <v-app :style="colorVapp">
      <onebox_toolbar
        :parentfolder="parentfolder"
        @loadfile="getSourceSystem()"
        @callstorage="loadstorage()"
      ></onebox_toolbar>
      <v-content>
        <!-- <v-divider></v-divider> -->
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <br />
          <br />
          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <!-- หน้าจอมือถือ -->
        <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
          <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
                <v-icon dark size="24" :color="color.theme">mdi-inbox-arrow-down</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  :style="headerPage"
                >{{ $t('allbusinessPage.inbox.title')}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-card>
        </v-list>
        <!-- หน้าจอธรรมดา -->
        <v-list nav :color="color.BG" v-else>
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-inbox-arrow-down</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  :style="headerPage"
                >{{ $t('allbusinessPage.inbox.title')}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        
        <v-card class="elevation-0" :color="color.BG" id="thiscontainer_allbusinessinbox">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <v-layout row wrap class="pl-3 pr-3">
                    <v-flex lg2 xs12>
                      <v-select
                        :color="color.theme"
                        prepend-inner-icon="sort"
                        hide-details
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                      <br />
                    </v-flex>
                    <v-flex xs6 lg2 offset-lg6 class="text-xs-center">
                      <v-dialog
                        ref="dialogFromdateinbox"
                        v-model="modalFromdateinbox"
                        :return-value.sync="fromdateinbox"
                        persistent
                        full-width
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            clearable
                            :color="color.theme"
                            :label="$t('allbusinessPage.outbox.fromdate')"
                            v-model="fromdateinbox"
                            @click:prepend="modalFromdateinbox = true"
                            prepend-inner-icon="event"
                            v-on="on"
                            readonly
                            dense
                            solo
                            @click:clear="todateinbox = ''"
                            class="my-hint-style pa-1 pt-0 pb-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromdateinbox"
                          scrollable
                          :locale="$t('default')"
                          @input="changeFromdateinbox()"
                          :color="color.theme"
                          :min="setdate_(todateinbox)"
                          :max="todateinbox"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="error"
                            @click.stop="modalFromdateinbox = false"
                          >{{$t('allbusinessPage.outbox.close')}}</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs6 lg2 class="text-xs-center">
                      <v-dialog
                        ref="dialogTodateinbox"
                        v-model="modalTodateinbox"
                        :return-value.sync="todateinbox"
                        persistent
                        full-width
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            clearable
                            readonly
                            :color="color.theme"
                            :label="$t('allbusinessPage.outbox.todate')"
                            v-model="todateinbox"
                            prepend-inner-icon="event"
                            @click:prepend="modalTodateinbox = true"
                            v-on="on"
                            solo
                            dense
                            class="my-hint-style pa-1 pt-0 pb-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="todateinbox"
                          scrollable
                          :locale="$t('default')"
                          @input="$refs.dialogTodateinbox.save(todateinbox), getInboxReceiver(),modalTodateinbox= false"
                          :color="color.theme"
                          :min="fromdateinbox"
                          :max="setdate(fromdateinbox)"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="error"
                            @click.stop="modalTodateinbox= false"
                          >{{$t('allbusinessPage.outbox.close')}}</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                  
                  <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6 elevation-1">
                        <v-list-item v-if="paginatedData.length === 0">
                        <v-list-item-content class="text-center">
                          <v-list-item-title v-text="$t('myinboxPage.Nodata')"></v-list-item-title>
                          <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                      v-else
                        v-for="item in paginatedData"
                        :key="item.title"
                        @dblclick="gotoSourceSystemIndex(item.system,item.system_id)"
                        @click="gotoSourceSystemIndex(item.system,item.system_id)"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar>
                          <v-icon large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content @dblclick="gotodirectory()">
                          <v-list-item-title v-text="item.system"></v-list-item-title>
                          <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if=" item.sum_receive > 0 "
                            class="ma-2"
                            color="red"
                            text-color="white"
                          >{{ item.sum_receive}}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- 
                      <v-divider inset></v-divider>-->
                    </v-list>
                  </div>

                  <div v-else>
                    <v-data-table
                      :headers="headers"
                      :items="systemdirectory"
                      item-key="name"
                      :no-data-text="$t('myinboxPage.Nodata')"
                      :show-select="false"
                      class="elevation-1"
                      item-selected
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.system_type`]>
                        <!-- <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ header.text }}</span>-->
                      </template>
                      <template v-slot:[`header.system`]="{ header }">
                        <span
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr
                          @click="gotoSourceSystemIndex(props.item.system,props.item.system_id)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            <v-icon x-large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                          </td>
                          <td>{{ props.item.system}}</td>
                          <td class="text-right">
                            <v-chip
                              v-if="props.item.sum_receive > 0"
                              class="ma-2"
                              color="red"
                              text-color="white"
                            >{{ props.item.sum_receive}}</v-chip>
                          </td>
                        </tr>
                      </template>
                      <!-- <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @contextmenu="test($event,item)"
                          @dblclick="gotoSourceSystemIndex(item.system)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            <v-icon x-large color="primary" style="font-weight: 100">move_to_inbox</v-icon>
                          </td>
                          <td>{{ item.system}}</td>
                        </tr>
                      </tbody>
                      </template>-->
                    </v-data-table>
                  </div>
                  <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="systemdirectory.length > 0">
                      <v-pagination v-model="page" :length="pageCount || 0" :color="color.theme"></v-pagination>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import format from "date-fns/format";
import gbfGenerate from "../globalFunctions/generateAuthorize";

// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  name: "allinbox_system",
  components: { onebox_toolbar },
  data: function() {
    return {
      modalTodateinbox: false,
      modalFromdateinbox: false,
      fromdateinbox: format(new Date(), "yyyy-MM-dd"),
      todateinbox: format(new Date(), "yyyy-MM-dd"),
      defaulticon: "",
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      nodata: this.$t("myinboxPage.Nodata"),
      loaddataprogress: false,
      systemdirectory: [],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "myinboxPage.HeaderSourceSystem.systemname",
          value: "system",
          width: "83%",
          sortable: false
        },
                        {
          text: "",
          value: "sum_receive",
          width: "10%",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata() {
      let companyname = ''
      if (this.$route.query.type === 2) {
        this.defaulticon = "business";
        this.getSourceSystem();
        if(this.$t('default') === 'th'){
          if (this.$route.query.nameth === undefined || this.$route.query.nameth === null || this.$route.query.nameth === ""){
            companyname = this.dataAccountActive['business_info']['first_name_th'] || "-"
          }else{
            companyname = this.$route.query.nameth
          }
        }else{
          if (typeof this.$route.query.nameen === undefined  || this.$route.query.nameen === null || this.$route.query.nameen === ""){
             companyname = this.dataAccountActive['business_info']['first_name_eng'] || "-"
          }else{
            companyname = this.$route.query.nameen
          }
        }
        return this.$t("toolbar.myinbox.Businessname") + ' ' + companyname;
      } else {
        this.defaulticon = "face";
        this.getSourceSystem();
        return this.$t("toolbar.myinbox.Personalname");
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.systemdirectory.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.systemdirectory.slice(start, start + this.size);
    },headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    }
  },
  methods: {
    setdate_(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() - 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    setdate(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() + 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
      // if(date_ === ''){
      //   var date = new Date(),
      //     y = date.getFullYear(),
      //     m = date.getMonth(),
      //     d = date.getDate() + 90;
      //   console.log("d", d);
      //   var Day = new Date(y, m, d).toISOString().substr(0, 10);
      //   console.log(Day);
      //   return Day;
      // }
    },
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    },
    getSourceSystem() {
      if (this.$route.query.type === 2) {
        if(this.$route.query.biz === undefined || this.$route.query.biz === null ||  this.$route.query.biz === ''){
          this.loadSourceSystem(this.dataAccountActive.business_info["business_id"],"2");
        }else{
          this.loadSourceSystemV2(this.$route.query.biz,"2",this.$route.query.fromdate,this.$route.query.todate);
        }
       
      } else {
        this.loadSourceSystem(this.dataAccountId, "1");
      }
    },

    async loadSourceSystem(_accountid, _type) {
      this.loaddataprogress = true;
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/getmyinbox",
          payload, 
          { withCredentials: false ,
          headers: { Authorization: auth.code },}
        );
        this.loaddataprogress = false;

        if (response.data.status === "OK") {
          this.systemdirectory = response.data.result;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        this.loaddataprogress = false;
      }
    },

    async loadSourceSystemV2(_accountid, _type, _fromdate, _todate) {
      this.loaddataprogress = true;
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        startdate: this.formatDatefromdoc(_fromdate,_todate),
        enddate: this.formatDatetodoc(_fromdate,_todate)
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v2/getmyinbox",
          payload,
          { withCredentials: false ,
           headers: { Authorization: auth.code },}
        );
        console.log(response);
        this.loaddataprogress = false;

        if (response.data.status === "OK") {
          this.systemdirectory = response.data.result;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        this.loaddataprogress = false;
      }
    },

    getBusiness(){
      let business = []
      if(this.dataBusinessProfile.length > 0){
        for(let i=0; i< this.dataBusinessProfile.length; i++){
          business.push(this.dataBusinessProfile[i]['business_id'])
        }
      }

      return business

    },
    async loadSourceSystemV3(){
      this.loaddataprogress = true;
      let payload = {
        receiver_id: this.getBusiness(),
        receiver_type: "2",
        startdate: this.formatDatefromdoc(this.fromdateinbox,this.todateinbox),
        enddate: this.formatDatetodoc(this.fromdateinbox,this.todateinbox)
      }
      let auth = await gbfGenerate.generateToken();
      try {
        let response = null;
        setTimeout(() => {
          if (response === null) {
            this.loadSourceSystemV3();
          }
        }, 15000);
        response = await this.axios.post(process.env.VUE_APP_SERVICE_GET_INBOX + '/api/v3/getmyinbox', payload,{
          headers: { Authorization: auth.code },
        })
          this.loaddataprogress = false;
          if(response.data.status === 'OK'){
            this.systemdirectory = response.data.result;
          }else{
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage
            });
          }
      } catch(error) {
        this.loaddataprogress = false;
        console.log(error)
      }
    },

    gotoSourceSystemIndex(_systemname,_systemname_id) {
      this.$router.push({
        path: "/allbusinessinbox-system",
        query: { sourcesystem: _systemname,systemid:_systemname_id, fromdate: this.fromdateinbox, todate: this.todateinbox}
      });
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatDatefromdoc(datefrom,docto) {
      console.log("datefrom1",datefrom);
      console.log("dateto2",docto);
      if((!datefrom || datefrom === undefined)&& docto){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdateinbox = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdateinbox",this.fromdateinbox);
          const [year, month, day] = this.fromdateinbox.split("-");
          return `${year}${month}${day}`;
        }
      }else if(datefrom && docto){
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }else if((!datefrom || datefrom === undefined ) && (!docto || docto === undefined)){
        return ""
      }else if(datefrom && (!docto || docto === undefined)){
        console.log('2');
          console.log("datefrom-", datefrom);
          var dateNow = new Date();
          var date = new Date(datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.fromdateinbox =new Date(datefrom).toISOString().substr(0, 10);
            this.todateinbox = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.fromdateinbox.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.fromdateinbox =new Date(datefrom).toISOString().substr(0, 10);
            this.todateinbox = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.fromdateinbox.split("-");
            return `${year}${month}${day}`;
          }
      }
    },
    formatDatetodoc(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todateinbox = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todateinbox.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todateinbox = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todateinbox.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    changeFromdateinbox() {
      this.modalFromdateinbox = false;
      this.$refs.dialogFromdateinbox.save(this.fromdateinbox);
      // this.getInboxReceiver();
    }
  },
  mounted() {
     this.loadSourceSystemV3()
    // this.getSourceSystem()
  }
};
</script>

<style>
#thiscontainer_allbusinessinbox {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>